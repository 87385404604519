@import "./styles/fpt/helpers/index";

.main {
  overflow: hidden;
  --Layout--background-color: white;
  background-color: var(--Layout--background-color);
}

.main.main--lightgray {
  --Layout--background-color: #{$grey_lighter};
}

@keyframes fade-in-arrow {
  from {
    opacity: 0;
    visibility: hidden;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fade-out-arrow {
  to {
    visibility: hidden;
    opacity: 0;
  }
}

.scroll-top_invisible {
  animation: fade-out-arrow 0.5s forwards;
}

.scroll-top_visible {
  animation: fade-in-arrow 0.5s forwards;
}

.scroll-top {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 15px;
  z-index: 10;
  transition: opacity 0.5s, visibility 0.5s;

  @media (min-width: 992px) {
    display: inline-flex;
  }

  button {
    padding: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    overflow: hidden;
    outline: 0;
    background: #e0e0e0;
    border: 0;
    border-radius: 25px;
    box-shadow: 0px 5px 15px 2px rgb(0 0 0 / 1%);

    .icon {
      padding: 0;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      line-height: 25px;
      font-size: 50px;
    }
  }
}

.main_wrapper,
.footer,
.footer_style {
  margin-top: $headerHeightLg;

  @media (max-width: 767px) {
    margin-top: $headerHeightSm;
  }
}

@media (max-width: 991px) {
  .scroll-top {
    visibility: hidden !important;
    display: none !important;
  }
}

.scroll-top button .icon::before {
  transition: transform 0.35s ease-out;
  transform: translateY(-40%);
}

.scroll-top button:hover .icon::before {
  transform: translateY(-60%);
}
