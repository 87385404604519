@font-face {
  font-display: block;
  font-family: "icons";
  src: url("../../../assets/fonts/icons/icomoon.eot");
  src: url("../../../assets/fonts/icons/icomoon.eot?#iefix") format("eot"),
    url("../../../assets/fonts/icons/icomoon.woff") format("woff"),
    url("../../../assets/fonts/icons/icomoon.ttf") format("truetype");
}

.icon-eDriveline:before {
  content: "\e900";
}

.icon-Energy-storage:before {
  content: "\e901";
}
.icon-Icona_Bus_ON-ROAD:before {
  content: "\e902";
}

.icon-Icona_Heavy_ON-ROAD:before {
  content: "\e903";
}

.icon-Icona_LCV_ON-ROAD:before {
  content: "\e904";
}

.icon-Icona_Medium:before {
  content: "\e905";
}

.icon-Icona_Mini-bus_ON-ROAD:before {
  content: "\e906";
}

// @font-face {
//   font-family: 'icons';
//   src:  url('../../../assets/fonts/icons/icomoon_new.eot?fve8sf');
//   src:  url('../../../assets/fonts/icons/icomoon_new.eot?fve8sf#iefix') format('embedded-opentype'),
//     url('../../../assets/fonts/icons/icomoon_new.ttf?fve8sf') format('truetype'),
//     url('../../../assets/fonts/icons/icomoon_new.woff?fve8sf') format('woff'),
//     url('../../../assets/fonts/icons/icomoon_new.svg?fve8sf#icomoon_new') format('svg');
//   font-weight: normal;
//   font-style: normal;
//   font-display: block;
// }


// .icon-highPerformance:before {
//   content: "\e907";
// }