@keyframes animateTop {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

body {
  font: 400 15px / auto $fontMain;
  overflow-x: hidden;
}

body:not([data-overflow]) > iframe {
  pointer-events: none;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

textarea:focus,
input:focus {
  outline: none;
}

.l-wrap {
  padding-top: $headerHeightSm;
}

.section-container,
.section-container-xl {
  padding-left: 30px;
  padding-right: 30px;
}

@include media-breakpoint-up(lg) {
  .section-container {
    padding-left: 60px;
    padding-right: 60px;
  }
  .section-container-xl {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@include media-breakpoint-up(xl) {
  .l-wrap {
    padding-top: $headerHeightLg;
  }
  .animate-top {
    animation-name: animateTop;
    animation-duration: 1s;
    // animation-fill-mode: forwards;
  }
}

.hidden {
  opacity: 0;
}

.slick-slide {
  height: auto; // ← that must not be ignored
}
.slick-track {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}

.slick-arrow {
  background-color: red;
}

.slick-slide {
  float: none;
  display: flex !important;
  flex-direction: column;
  > div {
    flex: 1;
    article {
      height: 100%;
    }
  }
}
