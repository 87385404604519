@keyframes animation_keyframes__t100pc__t40px {
  from {
    top: 100%;
  }
  to {
    top: 40px;
  }
}
.animation__animated__t100pc__t40px {
  animation-name: animation_keyframes__t100pc__t40px;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.animation__animated__slide_in {
  animation-name: animation_slide_in;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}
.animation__animated__slide_in_faster {
  animation-name: animation_slide_in;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes animation_slide_in {
  to {
    transform: translateY(0);
  }
}

@keyframes animation_keyframes__o0_trXn100pcYn50pc__o1_trX0Yn50pc {
  from {
    opacity: 0;
    transform: translateX(-100%) translateY(-50%);
  }
  to {
    opacity: 1;
    transform: translateX(0) translateY(-50%);
  }
}
.animation__animated__o0_trXn100pcYn50pc__o1_trX0Yn50pc {
  animation-name: animation_keyframes__o0_trXn100pcYn50pc__o1_trX0Yn50pc;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.animation__skip_animation {
  transition: none;
}
