@import "../styles/root";

@font-face {
  font-display: swap;
  font-family: "Roboto";
  src: url("../assets/fonts/roboto/Roboto-Regular.woff2") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Roboto-Medium";
  src: url("../assets/fonts/roboto/Roboto-Medium.woff2") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Roboto-Bold";
  src: url("../assets/fonts/roboto/Roboto-Bold.woff2") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Roboto-Light";
  src: url("../assets/fonts/roboto/Roboto-Light.woff2") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "CodecWarm-Bold";
  src: url("../assets/fonts/codec/Codec Warm Bold/Codec-Warm-Bold.woff") format("woff"),
    url("../assets/fonts/codec/Codec Warm Bold/Codec-Warm-Bold.eot") format("eot");
}

@font-face {
  font-display: swap;
  font-family: "CodecWarm-ExtraBold";
  src: url("../assets/fonts/codec/Codec Warm ExtraBold/Codec-Warm-ExtraBold.woff") format("woff"),
    url("../assets/fonts/codec/Codec Warm ExtraBold/Codec-Warm-ExtraBold.eot") format("eot");
}

@font-face {
  font-display: swap;
  font-family: "CodecWarm-Regular";
  src: url("../assets/fonts/codec/Codec Warm Regular/Codec-Warm-Regular.woff") format("woff"),
    url("../assets/fonts/codec/Codec Warm Regular/Codec-Warm-Regular.eot") format("eot");
}

@font-face {
  font-display: swap;
  font-family: "CodecWarm-ExtraLight";
  src: url("../assets/fonts/codec/Codec Warm ExtraLight/Codec-Warm-ExtraLight.woff") format("woff"),
    url("../assets/fonts/codec/Codec Warm ExtraLight/Codec-Warm-ExtraLight.eot") format("eot");
}

@font-face {
  font-display: swap;
  font-family: "OpenSans";
  src: url("../assets/fonts/open sans/OpenSans-Regular.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/open sans/OpenSans-Regular.woff") format("woff"),
    url("../assets/fonts/open sans/OpenSans-Regular.ttf") format("truetype"),
    url("../assets/fonts/open sans/OpenSans-Regular.svg#OpenSans") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "OpenSans";
  src: url("../assets/fonts/open sans/OpenSans-Italic-webfont.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/open sans/OpenSans-Italic-webfont.woff") format("woff"),
    url("../assets/fonts/open sans/OpenSans-Italic-webfont.ttf") format("truetype"),
    url("../assets/fonts/open sans/OpenSans-Italic-webfont.svg#OpenSansItalic") format("svg");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: "OpenSans";
  src: url("../assets/fonts/open sans/OpenSans-Semibold.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/open sans/OpenSans-Semibold.woff") format("woff"),
    url("../assets/fonts/open sans/OpenSans-Semibold.ttf") format("truetype"),
    url("../assets/fonts/open sans/OpenSans-Semibold.svg#OpenSans") format("svg");
  font-weight: 500;
  font-style: normal;
}

// @font-face {
//   font-display: swap;
//   font-family: "OpenSans";
//   src: url("../assets/fonts/open sans/OpenSans-Bold.eot?#iefix")
//       format("embedded-opentype"),
//     url("../assets/fonts/open sans/OpenSans-Bold.woff") format("woff"),
//     url("../assets/fonts/open sans/OpenSans-Bold.ttf") format("truetype"),
//     url("../assets/fonts/open sans/OpenSans-Bold.svg#OpenSans") format("svg");
//   font-weight: bold;
//   font-style: normal;
// }

@font-face {
  font-display: swap;
  font-family: "OpenSans";
  src: url("../assets/fonts/open sans/OpenSans-Light.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/open sans/OpenSans-Light.woff") format("woff"),
    url("../assets/fonts/open sans/OpenSans-Light.ttf") format("truetype"),
    url("../assets/fonts/open sans/OpenSans-Light.svg#OpenSans") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "OpenSansLight";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/open sans/OpenSans-Light.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/open sans/OpenSans-Light.woff") format("woff"),
    url("../assets/fonts/open sans/OpenSans-Light.ttf") format("truetype"),
    url("../assets/fonts/open sans/OpenSans-Light.svg#OpenSansLight") format("svg");
}

@font-face {
  font-display: swap;
  font-family: "OpenSansSemibold";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/open sans/OpenSans-Semibold.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/open sans/OpenSans-Semibold.woff") format("woff"),
    url("../assets/fonts/open sans/OpenSans-Semibold.ttf") format("truetype"),
    url("../assets/fonts/open sans/OpenSans-Semibold.svg#OpenSansSemibold") format("svg");
}

@font-face {
  font-display: swap;
  font-family: "OpenSansBold";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/open sans/OpenSans-Bold.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/open sans/OpenSans-Bold.woff") format("woff"),
    url("../assets/fonts/open sans/OpenSans-Bold.ttf") format("truetype"),
    url("../assets/fonts/open sans/OpenSans-Bold.svg#OpenSans") format("svg");
}

@font-face {
  font-display: swap;
  font-family: "OpenSansItalic";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/open sans/OpenSans-Italic-webfont.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/open sans/OpenSans-Italic-webfont.woff") format("woff"),
    url("../assets/fonts/open sans/OpenSans-Italic-webfont.ttf") format("truetype"),
    url("../assets/fonts/open sans/OpenSans-Italic-webfont.svg#OpenSansItalic") format("svg");
}

@font-face {
  font-display: swap;
  font-family: "OpenSansBoldItalic";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/open sans/OpenSans-Bold-italic.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/open sans/OpenSans-Bold-italic.woff") format("woff"),
    url("../assets/fonts/open sans/OpenSans-Bold-italic.ttf") format("truetype"),
    url("../assets/fonts/open sans/OpenSans-Bold-italic.svg#OpenSansBoldItalic") format("svg");
}

@font-face {
  font-display: swap;
  font-family: "ZeitungProExtraboldItalic";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/ZeitungPro/ZeitungPro-ExtraboldItalic.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/ZeitungPro/ZeitungPro-ExtraboldItalic.woff") format("woff"),
    url("../assets/fonts/ZeitungPro/ZeitungPro-ExtraboldItalic.ttf") format("truetype"),
    url("../assets/fonts/ZeitungPro/ZeitungPro-ExtraboldItalic.svg#ZeitungProExtraboldItalic") format("svg");
}

@font-face {
  font-display: swap;
  font-family: "ZeitungProRegular";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/ZeitungPro/ZeitungPro-Regular.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/ZeitungPro/ZeitungPro-Regular.woff") format("woff"),
    url("../assets/fonts/ZeitungPro/ZeitungPro-Regular.ttf") format("truetype"),
    url("../assets/fonts/ZeitungPro/ZeitungPro-Regular.svg#ZeitungProRegular") format("svg");
}

@font-face {
  font-display: swap;
  font-family: "Helvetica";
  src: url("../assets/fonts/helvetica/Helvetica.ttf") format("truetype");
}

.Desktop36px_Title-section-primary02_Greyscale05_Black {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  color: var(--total-black-10);
}

.Desktop24px_Title-article02_Greyscale05_Black {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: var(--total-black-10);
}

.Desktop18px_Subtitle-article01_Primary1_Blue {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #1554fe;
}

.Mobile14px_Body302_Greyscale04_Light-Black {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--light-black-9);
}

.Mobile16px_Body102_Greyscale05_Black {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: var(--total-black-10);
}

.Note02_Greyscale04_Light-Black-2 {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: var(--light-black-9);
}

.Note02_Greyscale03_Dark-Grey-2 {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: var(--dark-grey-8);
}

.Mobile34px_Title02_Greyscale05_Black {
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  color: var(--total-black-10);
}

.Mobile20px_Title-article02_Greyscale05_Black {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: var(--total-black-10);
}

.Mobile14px_Body302_Greyscale05_Black {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--total-black-10);
}