.title-section-xl {
  font: 45px $fontSecond;
}

.title-section {
  font: 30px $fontSecond;
}

.f-s-14 {
  font-size: 14px !important;
}
.f-s-15 {
  font-size: 15px !important;
}

.c-red {
  color: $c-red;
}
