.bg-red {
  background-color: $c-red;
  color: #fff;
}

.bg-white {
  background-color: #fff;
}

.bg-grey_light {
  background-color: $grey_lighter;
}

$gutterList: 0 5 10 15 20 30 40 50 100 150;
@each $marginNum in $gutterList {
  .m-#{$marginNum} {
    margin: #{$marginNum}px !important;
  }
  .mt-#{$marginNum} {
    margin-top: #{$marginNum}px !important;
  }
  .mb-#{$marginNum} {
    margin-bottom: #{$marginNum}px !important;
  }
  .ml-#{$marginNum} {
    margin-left: #{$marginNum}px !important;
  }
  .mr-#{$marginNum} {
    margin-right: #{$marginNum}px !important;
  }
  .mx-#{$marginNum} {
    margin-right: #{$marginNum}px !important;
    margin-left: #{$marginNum}px !important;
  }
  .my-#{$marginNum} {
    margin-top: #{$marginNum}px !important;
    margin-bottom: #{$marginNum}px !important;
  }
}

@each $paddingNum in $gutterList {
  .p-#{$paddingNum} {
    padding: #{$paddingNum}px !important;
  }
  .pt-#{$paddingNum} {
    padding-top: #{$paddingNum}px !important;
  }
  .pb-#{$paddingNum} {
    padding-bottom: #{$paddingNum}px !important;
  }
  .pl-#{$paddingNum} {
    padding-left: #{$paddingNum}px !important;
  }
  .pr-#{$paddingNum} {
    padding-right: #{$paddingNum}px !important;
  }
  .px-#{$paddingNum} {
    padding-right: #{$paddingNum}px !important;
    padding-left: #{$paddingNum}px !important;
  }
  .py-#{$paddingNum} {
    padding-top: #{$paddingNum}px !important;
    padding-bottom: #{$paddingNum}px !important;
  }
}

.arrow-right,
.arrow-left {
  &:before {
    content: '';
    height: 10px;
    width: 10px;
    border-color: #000;
    border-style: solid;
    border-width: 0 0 1px 1px;
    display: inline-block;
    vertical-align: middle;
    transition: all 0.4s ease 0s;
  }
}

.arrow__white {
  &:before {
    border-color: #fff;
  }
}

.arrow-left:before {
  transform: rotate(45deg);
  margin-left: 5px;
}

.arrow-right:before {
  transform: rotate(225deg);
  margin-left: -5px;
}

.carousel-double {
  .use-spring-carousel-item {
    align-items: center;
    justify-content: center;
  }
}
