// @import "../styles/font";
$c-red: #e41e2d;
$c-grey: #d4d4d4;
$c-sgrey: #d8d8d8;
$white: #ffffff;
$grey_elight: #f4f4f4;
$grey_slight: #fafafa;
$grey_lighter: #f1f1f1;
$grey_light_10: lighten(black, 90%);
$grey_light_40: lighten(black, 40%);
$switch_inactive_grey: #adadad;
$grey_light: #9e9e9e;
$grey_dark: #979797;
$grey_darker: #717171;
$grey_darkest: #434343;
$backdrop: rgba(0, 0, 0, 0.5);
$green_number: #00c61c;
$facebook: #47639d;
$twitter: #26b2e7;
$g_plus: #dc4e41;
$linkedin: #26b2e7;
$youtube: #eb1e01;
$instagram: #1c5b84;
$linkedin: #0179b6;
$flickr: #0063db;

$headerHeightSm: 70px;
$headerHeightLg: 90px;

$down_sm: 330;

$fontMain: 'OpenSans', Helvetica, Arial, sans-serif, sans-serif;
$fontSecond: 'ZeitungProExtraboldItalic', Helvetica, Arial, sans-serif;
$fontIco: 'icons';
