@font-face {
    font-family: "icons";
    src: url("../../../assets/fonts/icons/icons.eot?c4ea2c35892214c029f7c6dd4aee3c92#iefix") format("embedded-opentype"),
url("../../../assets/fonts/icons/icons.woff2?c4ea2c35892214c029f7c6dd4aee3c92") format("woff2"),
url("../../../assets/fonts/icons/icons.woff?c4ea2c35892214c029f7c6dd4aee3c92") format("woff");
}

i[class^="icon-"]:before, i[class*=' icon-']:before, .icon {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-3020_floater:before {
    content: "\f101";
}
.icon-agricultural:before {
    content: "\f102";
}
.icon-agricultureNew:before {
    content: "\f103";
    font-size: 1.3rem;
}
.icon-anchor:before {
    content: "\f104";
}
.icon-arrow_back:before {
    content: "\f105";
}
.icon-arrow-left:before {
    content: "\f106";
}
.icon-arrow-right:before {
    content: "\f107";
}
.icon-arrow-up:before {
    content: "\f108";
}
.icon-arrow:before {
    content: "\f109";
}
.icon-arrows_down:before {
    content: "\f10a";
}
.icon-arrows:before {
    content: "\f10b";
}
.icon-articulate_truck:before {
    content: "\f10c";
}
.icon-axies:before {
    content: "\f10d";
}
.icon-backhoe_loader:before {
    content: "\f10e";
}
.icon-bag:before {
    content: "\f10f";
}
.icon-bale_wagon:before {
    content: "\f110";
}
.icon-Battery:before {
    content: "\f111";
}
.icon-blog:before {
    content: "\f112";
}
.icon-bus:before {
    content: "\f113";
}
.icon-change:before {
    content: "\f114";
}
.icon-clock:before {
    content: "\f115";
}
.icon-close:before {
    content: "\f116";
}
.icon-concrete_mixer-32:before {
    content: "\f117";
}
.icon-concrete_mixer-70:before {
    content: "\f118";
}
.icon-construction:before {
    content: "\f119";
}
.icon-constructionNew:before {
    content: "\f11a";
    font-size: 1.3rem;
}
.icon-cube_arrows:before {
    content: "\f11b";
}
.icon-cv:before {
    content: "\f11c";
}
.icon-download:before {
    content: "\f11d";
}
.icon-dozer:before {
    content: "\f11e";
}
.icon-eDriveline:before {
    content: "\f11f";
}
.icon-eDrivlineIconMenu:before {
    content: "\f120";
}
.icon-Energy_storage:before {
    content: "\f121";
}
.icon-error:before {
    content: "\f122";
}
.icon-facebook_bottom_bar_OLD:before {
    content: "\f123";
}
.icon-facebook_bottom_bar:before {
    content: "\f124";
}
.icon-facebook-logo:before {
    content: "\f125";
}
.icon-facebook:before {
    content: "\f126";
}
.icon-film-video:before {
    content: "\f127";
}
.icon-film:before {
    content: "\f128";
}
.icon-filter:before {
    content: "\f129";
}
.icon-fire_fighting-35:before {
    content: "\f12a";
}
.icon-fire_fighting-65:before {
    content: "\f12b";
}
.icon-flash:before {
    content: "\f12c";
}
.icon-flickr_bottom_bar_BK:before {
    content: "\f12d";
}
.icon-flickr_bottom_bar:before {
    content: "\f12e";
}
.icon-foglia:before {
    content: "\f12f";
}
.icon-forestry_machine-36:before {
    content: "\f130";
}
.icon-forestry_machine-61:before {
    content: "\f131";
}
.icon-forklift:before {
    content: "\f132";
}
.icon-g_drive_marine:before {
    content: "\f133";
}
.icon-g_drive:before {
    content: "\f134";
}
.icon-g_plus_bottom_bar:before {
    content: "\f135";
}
.icon-g_plus:before {
    content: "\f136";
}
.icon-g-drive_marine:before {
    content: "\f137";
}
.icon-gear:before {
    content: "\f138";
}
.icon-genset:before {
    content: "\f139";
}
.icon-geo:before {
    content: "\f13a";
}
.icon-globe:before {
    content: "\f13b";
}
.icon-go_to:before {
    content: "\f13c";
}
.icon-grader-37:before {
    content: "\f13d";
}
.icon-grader-60:before {
    content: "\f13e";
}
.icon-grape_harvester:before {
    content: "\f13f";
}
.icon-graph:before {
    content: "\f140";
}
.icon-harvester:before {
    content: "\f141";
}
.icon-headphone:before {
    content: "\f142";
}
.icon-highPerformance:before {
    content: "\f143";
}
.icon-history-company:before {
    content: "\f144";
}
.icon-history-flash:before {
    content: "\f145";
}
.icon-history-gear:before {
    content: "\f146";
}
.icon-history-innovate:before {
    content: "\f147";
}
.icon-history-logo:before {
    content: "\f148";
}
.icon-history-prize:before {
    content: "\f149";
}
.icon-history-product:before {
    content: "\f14a";
}
.icon-history-road:before {
    content: "\f14b";
}
.icon-home:before {
    content: "\f14c";
}
.icon-Icona_Bus_ON_Road_New:before {
    content: "\f14d";
}
.icon-Icona_Heavy_ON-ROAD:before {
    content: "\f14e";
}
.icon-Icona_LCV_ON-ROAD:before {
    content: "\f14f";
}
.icon-Icona_Mini-bus_ON-ROAD:before {
    content: "\f150";
}
.icon-Icona-Medium-New:before {
    content: "\f151";
}
.icon-increase:before {
    content: "\f152";
}
.icon-industrial_pivot:before {
    content: "\f153";
}
.icon-industrial_station:before {
    content: "\f154";
}
.icon-industrial:before {
    content: "\f155";
}
.icon-instagram_bottom_bar:before {
    content: "\f156";
}
.icon-ipu:before {
    content: "\f157";
}
.icon-irrigation_power_unit:before {
    content: "\f158";
}
.icon-job_globe:before {
    content: "\f159";
}
.icon-lens_bk:before {
    content: "\f15a";
}
.icon-lens:before {
    content: "\f15b";
}
.icon-levels:before {
    content: "\f15c";
}
.icon-lift_truck:before {
    content: "\f15d";
}
.icon-light_commercial_vehicle:before {
    content: "\f15e";
}
.icon-linkedin_bottom_bar:before {
    content: "\f15f";
}
.icon-linkedin:before {
    content: "\f160";
}
.icon-location-marker:before {
    content: "\f161";
}
.icon-location:before {
    content: "\f162";
}
.icon-lock:before {
    content: "\f163";
}
.icon-locotrattore:before {
    content: "\f164";
}
.icon-mail:before {
    content: "\f165";
}
.icon-maintenance:before {
    content: "\f166";
}
.icon-marine_pleasure:before {
    content: "\f167";
}
.icon-marine_professional:before {
    content: "\f168";
}
.icon-marine-boating:before {
    content: "\f169";
}
.icon-marine-commercial:before {
    content: "\f16a";
}
.icon-marker:before {
    content: "\f16b";
}
.icon-marmitta:before {
    content: "\f16c";
}
.icon-medium_truck:before {
    content: "\f16d";
}
.icon-mixer_wagon-52:before {
    content: "\f16e";
}
.icon-mixer_wagon-55:before {
    content: "\f16f";
}
.icon-mobile_crane-31:before {
    content: "\f170";
}
.icon-mobile_crane-66:before {
    content: "\f171";
}
.icon-mobile_crane-67:before {
    content: "\f172";
}
.icon-money:before {
    content: "\f173";
}
.icon-newoil:before {
    content: "\f174";
}
.icon-newsletter:before {
    content: "\f175";
}
.icon-offroad:before {
    content: "\f176";
}
.icon-oil-barrell:before {
    content: "\f177";
}
.icon-oil:before {
    content: "\f178";
}
.icon-ok:before {
    content: "\f179";
}
.icon-pdf:before {
    content: "\f17a";
}
.icon-performance:before {
    content: "\f17b";
}
.icon-person:before {
    content: "\f17c";
}
.icon-phone:before {
    content: "\f17d";
}
.icon-piston_BK:before {
    content: "\f17e";
}
.icon-piston:before {
    content: "\f17f";
}
.icon-road:before {
    content: "\f180";
}
.icon-sgombra_neve:before {
    content: "\f181";
}
.icon-share:before {
    content: "\f182";
}
.icon-skid_steer_loader:before {
    content: "\f183";
}
.icon-slideshow_arrow_next:before {
    content: "\f184";
}
.icon-slideshow_arrow_prev:before {
    content: "\f185";
}
.icon-soundproofed:before {
    content: "\f186";
}
.icon-spotify_bottom_bar:before {
    content: "\f187";
}
.icon-sprayer:before {
    content: "\f188";
}
.icon-sprinkler:before {
    content: "\f189";
}
.icon-sweeper:before {
    content: "\f18a";
}
.icon-telehandler:before {
    content: "\f18b";
}
.icon-trasmission:before {
    content: "\f18c";
}
.icon-truck:before {
    content: "\f18d";
}
.icon-twitter_bottom_bar:before {
    content: "\f18e";
}
.icon-twitter:before {
    content: "\f18f";
}
.icon-van:before {
    content: "\f190";
}
.icon-vehicle-bus:before {
    content: "\f191";
}
.icon-vehicle-camion:before {
    content: "\f192";
}
.icon-vehicle-commercial:before {
    content: "\f193";
}
.icon-wechat_black:before {
    content: "\f194";
}
.icon-wechat_white:before {
    content: "\f195";
}
.icon-wechat:before {
    content: "\f196";
}
.icon-weibo_white:before {
    content: "\f197";
}
.icon-wheel_loader-47:before {
    content: "\f198";
}
.icon-wheel_loader-68:before {
    content: "\f199";
}
.icon-wheeled_excavator-48:before {
    content: "\f19a";
}
.icon-wheeled_excavator-64:before {
    content: "\f19b";
}
.icon-wifi:before {
    content: "\f19c";
}
.icon-windrower:before {
    content: "\f19d";
}
.icon-youtube_bottom_bar:before {
    content: "\f19e";
}
